import axios from "axios";

const api_request = (headers) => {
  return new axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: process.env.REACT_APP_ENVIRONMENT.toLowerCase() === "development" ? 300000 : 15000,
    // headers: {
    //   "Content-Type": "application/json",
    //   "x-email": auth_data.email,
    //   "x-guserid": auth_data.guserid,
    //   "x-token": auth_data.token,
    // },
  });
};

export const countries_fiscal_get = async () => {
  return new Promise((resolve, reject) => {
    api_request()
      .get("/actions/countries-fiscal")
      .then((res) => resolve(res))
      .catch((err) => {
        reject({ ...err });
      });
  });
};

export const follow_redirect_post = async (payload) => {
  return new Promise((resolve, reject) => {
    api_request()
      .post("/actions/follow-redirect", payload)
      .then((res) => resolve(res))
      .catch((err) => {
        reject({ ...err });
      });
  });
};

export const app_active_notice_get = async (auth_data) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get(`/actions/app-active-notices`)
      .then((res) => resolve(res))
      .catch((err) => {
        reject({ ...err });
      });
  });
};

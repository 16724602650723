import dicebarString from "utils/dicebear";
export const getProfileImage = (data, width) => {
  if (data !== undefined) {
    let image = !!data.picture ? data.picture : data.user.picture;
    if (image === "" || image === null || image === undefined) {
      dicebarString(data.name);
    }

    if (width) {
      image = `${image}?width=${width}`;
    }
    return image;
  }
};

import React from "react";
import { Button } from "antd";
import { LockOutlined } from "@ant-design/icons";

const backOfficeUrl = process.env.REACT_APP_BACKOFFICE_URL;

const AdminButton = ({ auth }) => {
  if (auth?.admin !== true) return <></>;
  if (!backOfficeUrl) return <></>;
  return (
    <div style={{ marginRight: 10 }}>
      <a href={backOfficeUrl} target="_blank" rel="noopener noreferrer">
        <Button size="small" type="primary" className="sp-button">
          <LockOutlined />
        </Button>
      </a>
    </div>
  );
};

export default AdminButton;

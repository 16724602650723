import {
  EuroCircleOutlined,
  DatabaseOutlined,
  DashboardOutlined,
  ReadOutlined,
  TableOutlined,
  UserOutlined,
  BankOutlined,
  NotificationOutlined,
  SettingOutlined,
  UsergroupAddOutlined,
  RollbackOutlined,
  UnorderedListOutlined,
  FileDoneOutlined,
  FileProtectOutlined,
  AppstoreOutlined,
  ShopOutlined,
  CreditCardOutlined,
  WalletOutlined,
  HomeOutlined,
  AppstoreAddOutlined,
  ClusterOutlined,
  CheckCircleOutlined,
  CheckSquareOutlined,
  AimOutlined,
} from "@ant-design/icons";
import { APP_PREFIX_PATH } from "configs/AppConfig";

const adminNavTree = [
  {
    scope: ["admin", "staff"],
    key: "admin",
    featured: true,
    path: `${APP_PREFIX_PATH}/admin`,
    title: "sidenav.admin",
    icon: DatabaseOutlined,
    breadcrumb: false,
    submenu: [
      {
        scope: ["admin", "staff"],
        key: "admin-dashboard",
        path: `${APP_PREFIX_PATH}/admin/dashboard`,
        title: "sidenav.admin-dashboard",
        description: "Dashboard de Administração",
        tags: ["dashboard", "admin"],
        icon: DashboardOutlined,
        breadcrumb: true,
        submenu: [],
      },

      {
        scope: ["admin", "staff"],
        key: "admin-financial-management",
        path: `${APP_PREFIX_PATH}/admin/financial-management`,
        title: "sidenav.admin-financial_management",
        description: "Gestão Financeira",
        tags: [],
        icon: BankOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "admin-financial-management-dashboard",
            path: `${APP_PREFIX_PATH}/admin/financial-management/dashboard`,
            title: "sidenav.admin-financial_management_dashboard",
            icon: DashboardOutlined,
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "admin-financial-management-reconciliation",
            path: `${APP_PREFIX_PATH}/admin/financial-management/reconciliation`,
            title: "sidenav.admin-financial_management_reconciliation",
            icon: ReadOutlined,
            breadcrumb: true,
            submenu: [],
          },

          {
            key: "admin-financial-management-transactions",
            path: `${APP_PREFIX_PATH}/admin/financial-management/transactions`,
            title: "sidenav.admin-financial_management_transactions",
            icon: FileDoneOutlined,
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "admin-financial-management-movements",
            path: `${APP_PREFIX_PATH}/admin/financial-management/movements`,
            title: "sidenav.admin-financial_management_movements",
            icon: TableOutlined,
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "admin-financial-management-categories",
            path: `${APP_PREFIX_PATH}/admin/financial-management/categories`,
            title: "sidenav.admin-financial_management_categories",
            icon: AppstoreAddOutlined,
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "admin-financial-management-sub-categories",
            path: `${APP_PREFIX_PATH}/admin/financial-management/sub-categories`,
            title: "sidenav.admin-financial_management_sub_categories",
            icon: AppstoreOutlined,
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "admin-financial-management-companies",
            path: `${APP_PREFIX_PATH}/admin/financial-management/companies`,
            title: "sidenav.admin-financial_management_companies",
            icon: HomeOutlined,
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "admin-financial-management-banks",
            path: `${APP_PREFIX_PATH}/admin/financial-management/banks`,
            title: "sidenav.admin-financial_management_banks",
            icon: BankOutlined,
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "admin-financial-management-accounts",
            path: `${APP_PREFIX_PATH}/admin/financial-management/accounts`,
            title: "sidenav.admin-financial_management_accounts",
            icon: WalletOutlined,
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "admin-financial-management-cards",
            path: `${APP_PREFIX_PATH}/admin/financial-management/cards`,
            title: "sidenav.admin-financial_management_cards",
            icon: CreditCardOutlined,
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "admin-financial-management-entities",
            path: `${APP_PREFIX_PATH}/admin/financial-management/entities`,
            title: "sidenav.admin-financial_management_entities",
            icon: ShopOutlined,
            breadcrumb: true,
            submenu: [],
          },
        ],
      },

      {
        scope: ["admin", "staff"],
        key: "admin-producers",
        path: `${APP_PREFIX_PATH}/admin/producers`,
        title: "sidenav.admin-producers",
        description: "Produtores",
        tags: ["produtores", "admin"],
        icon: UsergroupAddOutlined,
        breadcrumb: true,
        submenu: [],
      },

      {
        scope: ["admin", "staff"],
        key: "admin-products",
        path: `${APP_PREFIX_PATH}/admin/products`,

        title: "sidenav.admin-products",
        description: "Produtos",
        tags: ["produtos", "admin", "marketplace"],
        icon: AppstoreOutlined,
        breadcrumb: true,
        submenu: [
          {
            scope: ["admin", "staff"],
            key: "admin-products-approval",
            path: `${APP_PREFIX_PATH}/admin/products/products-approval`,
            title: "sidenav.admin-products-approval",
            description: "Aprovação de Produtos",
            tags: ["aprovação", "produtos", "admin"],
            icon: CheckCircleOutlined,
            breadcrumb: true,
            submenu: [],
          },
          {
            scope: ["admin", "staff"],
            key: "admin-products-marketplace-approval",
            path: `${APP_PREFIX_PATH}/admin/products/marketplace-products-approval`,
            title: "sidenav.admin-marketplace-products-approval",
            description: "Aprovação de Produtos - Marketplace",
            tags: ["aprovação", "produtos", "marketplace", "admin"],
            icon: CheckSquareOutlined,
            breadcrumb: true,
            submenu: [],
          },
          {
            scope: ["admin", "staff"],
            key: "admin-products-list",
            path: `${APP_PREFIX_PATH}/admin/products/products-list`,
            title: "sidenav.admin-products-list",
            description: "Lista de Produtos",
            tags: ["list", "produtos", "admin"],
            icon: AppstoreOutlined,
            breadcrumb: true,
            submenu: [],
          },
        ],
      },

      {
        scope: ["admin", "staff"],
        key: "admin-bank-accounts",
        path: `${APP_PREFIX_PATH}/admin/bank-accounts`,
        title: "sidenav.admin-bank-accounts",
        description: "Contas Bancárias",
        tags: ["contas", "bancárias", "admin"],
        icon: BankOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        scope: ["admin", "staff"],
        key: "admin-refunds",
        path: `${APP_PREFIX_PATH}/admin/refunds`,
        title: "sidenav.admin-refunds",
        description: "Pedidos de Reembolso",
        tags: ["reembolsos", "admin"],
        icon: RollbackOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        scope: ["admin", "staff"],
        key: "admin-payouts",
        path: `${APP_PREFIX_PATH}/admin/payouts`,
        title: "sidenav.admin-payouts",
        description: "Pagamentos a Produtores",
        tags: ["pagamentos", "admin"],
        icon: EuroCircleOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        scope: ["admin", "staff"],
        key: "admin-users-students",
        path: `${APP_PREFIX_PATH}/admin/users-students`,
        title: "sidenav.admin-users-students",
        description: "Utilizadores Estudantes",
        tags: ["utilizadores", "estudantes", "admin"],
        icon: UserOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        scope: ["admin", "staff"],
        key: "admin-buyers",
        path: `${APP_PREFIX_PATH}/admin/buyers`,
        description: "Compradores",
        tags: ["compradores", "admin"],
        title: "sidenav.admin-buyers",
        icon: UserOutlined,
        breadcrumb: true,
        submenu: [],
      },

      {
        scope: ["admin", "staff"],
        key: "admin-transactions",
        path: `${APP_PREFIX_PATH}/admin/transactions`,
        title: "sidenav.admin-transactions",
        description: "Transações / Operaçõs Financeiras",
        tags: ["transações", "admin"],
        icon: UnorderedListOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        scope: ["admin", "staff"],
        key: "admin-invoices",
        path: `${APP_PREFIX_PATH}/admin/invoices`,
        title: "sidenav.admin-invoices",
        description: "Faturação de comissões / outros",
        tags: ["faturação", "admin"],
        icon: FileDoneOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        scope: ["admin"],
        key: "admin-automatic-withdrawal",
        path: `${APP_PREFIX_PATH}/admin/automatic-withdrawal`,
        title: "sidenav.admin-automatic-withdrawal",
        description: "Levantamento Automático de Fundos",
        tags: ["Levantamento", "Fundos", "admin"],
        icon: WalletOutlined,
        breadcrumb: true,
        submenu: [],
      },

      {
        scope: ["admin"],
        key: "admin-tools",
        path: `${APP_PREFIX_PATH}/admin/tools`,
        title: "sidenav.admin-tools",
        description: "Ferramentas",
        tags: ["notificações", "admin"],
        icon: SettingOutlined,
        breadcrumb: true,
        submenu: [
          {
            scope: ["admin"],
            key: "admin-tools-notices",
            path: `${APP_PREFIX_PATH}/admin/notices`,
            title: "sidenav.admin-notices",
            description: "Notificações da Aplicação",
            tags: ["notificações", "admin"],
            icon: NotificationOutlined,
            breadcrumb: true,
            submenu: [],
          },
          {
            scope: ["admin"],
            key: "admin-tools-fbjobs",
            path: `${APP_PREFIX_PATH}/admin/fbjobs`,
            title: "sidenav.admin-fbjobs",
            description: "fbJobs",
            tags: ["fbjobs", "admin"],
            icon: ClusterOutlined,
            breadcrumb: true,
            submenu: [],
          },
          {
            scope: ["admin"],
            key: "admin-tools-kill-switch",
            path: `${APP_PREFIX_PATH}/admin/kill-switch`,
            title: "sidenav.admin-kill-switch",
            description: "Kill Switch",
            tags: ["kill switch", "admin"],
            icon: AimOutlined,
            breadcrumb: true,
            submenu: [],
          },
          {
            scope: ["admin"],
            key: "admin-tools-dac7-dpi",
            path: `${APP_PREFIX_PATH}/admin/dac7-dpi`,
            title: "sidenav.admin-dac7-dpi",
            description: "DAC7 / DPI",
            tags: ["dac7", "dpi", "admin"],
            icon: FileProtectOutlined,
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
    ],
  },
];

let navigationConfig;

navigationConfig = [...adminNavTree];

export default navigationConfig;

import React from "react";
import { Alert, Button } from "antd";
import { Link } from "react-router-dom";
import moment from "moment-timezone";

const AccountStatus = ({ authData }) => {
  if (!authData) return <></>;

  if (!authData?.active_products_count) return <></>;
  if (authData?.active_products_count <= 0) {
    return <></>;
  }
  if (authData?.allow_internal_management === true) {
    return <></>;
  }

  const howLongRegistered = moment().diff(moment(authData?.registerDate), "days");

  if (howLongRegistered < 60 && authData?.account_status === "pending") {
    return <></>;
  }

  let status = "pending";
  let statusColor = "warning";
  let statusLink = "https://ajuda.salespark.io/help/documentos-e-comprovativos-diretiva-ue-2021-slash-514";
  let statusText = (
    <>
      Ainda faltam alguns documentos do teu negócio/empresa.{" "}
      <Link to={{ pathname: "/account/company-data" }}>Ir para os dados do negócio/empresa</Link>
    </>
  );

  if (authData?.account_status) {
    status = authData?.account_status;
  }

  if (status === "active" || status === "active-missing-documents" || status === "under-review") {
    return <></>;
  }

  if (status === "suspended") {
    statusColor = "error";
    statusLink = "https://ajuda.salespark.io/help/conta-suspensa";
    statusText = "A tua conta foi suspensa temporariamente para análise.";
  }

  if (status === "closed") {
    statusColor = "error";
    statusLink = "https://ajuda.salespark.io/help/conta-encerrada";
    statusText = "A tua conta está encerrada.";
  }

  if (status === "deleted") {
    statusColor = "error";
    statusLink = "https://ajuda.salespark.io/help/conta-eliminada";
    statusText = "A tua conta vai ser eliminada brevemente.";
  }

  // pending, suspended, under-review, active, active-missing-documents, closed, deleted
  return (
    <div className="salespark-notice">
      <Alert
        key={"company-status"}
        message={statusText}
        type={statusColor}
        banner
        action={
          <Link to={{ pathname: statusLink }} target="_blank">
            <Button size="small" type="link">
              Saber mais...
            </Button>
          </Link>
        }
      />
    </div>
  );
};

export default AccountStatus;

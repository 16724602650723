import axios from "axios";

const api_request = (auth_data) => {
  return new axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: process.env.REACT_APP_ENVIRONMENT.toLowerCase() === "development" ? 300000 : 15000,
    headers: {
      "Content-Type": "application/json",
      "x-email": auth_data.email,
      "x-guserid": auth_data.guserid,
      "x-token": auth_data.token,
    },
  });
};

const checkAuth = (resp) => {
  //TODO:
  //Impement on components on catch: If status is 401 execute Google Signout
};

export const producers_online_post = async (auth_data, payload) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .post("/producers/online", payload)
      .then((res) => resolve(res))
      .catch((err) => {
        reject({ ...err });
      });
  });
};

export const search_get = async (auth_data, text) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get("/producers/search", {
        params: {
          text,
        },
      })
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const producer_onboarding_put = async (auth_data, payload) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .put("/producers/onboarding", {
        payload,
      })
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const producer_balance_get = async (auth_data) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get("/producers/balance")
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const producer_alerts_get = async (auth_data) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get("/producers/alerts")
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const producer_payouts_status_get = async (auth_data) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get("/producers/payouts-status")
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const producer_onboarding_get = async (auth_data) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get("/producers/onboarding")
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const producer_get = async (auth_data) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get("/producers")
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const producer_details_get = async (auth_data) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get("/producers/details")
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const producer_update = async (auth_data, payload) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .put("/producers", {
        payload,
      })
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const producer_settings_get = async (auth_data) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get("/producers/settings")
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};
export const producer_settings_update = async (auth_data, payload) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .put("/producers/settings", payload)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const producer_details_update = async (auth_data, payload) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .put("/producers/details", {
        payload,
      })
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const producer_terms_accepted_update = async (auth_data) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .put("/producers/terms_accepted")
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const producer_sms_balance_get = async (auth_data) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get("/producers/sms-balance")
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const producer_validate_phone_post = async (auth_data, payload) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .post("/producers/validate-phone", payload)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const producer_validate_phone_confirm_post = async (auth_data, payload) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .post("/producers/validate-phone-confirm", payload)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const producer_onboarding_videos_get = async (auth_data) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get("/producers/onboarding-videos")
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const producer_onboarding_videos_put = async (auth_data, payload) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .put("/producers/onboarding-videos", payload)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const producer_reactivate_post = async (auth_data) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .post("/producers/reactivate")
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const producer_preferences_get = async (auth_data) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get("/producers/preferences")
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const producer_preferences_put = async (auth_data, payload) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .put("/producers/preferences", payload)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const producer_2fa_request = async (auth_data, payload) => {
  return new Promise((resolve, reject) => {
    try {
      api_request(auth_data)
        .post("/producers/admin/2fa/request", payload)
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    } catch (err) {
      reject({ ...err });
    }
  });
};

export const producer_2fa_confirm = async (auth_data, payload) => {
  return new Promise((resolve, reject) => {
    try {
      api_request(auth_data)
        .post("/producers/admin/2fa/confirm", payload)
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    } catch (err) {
      reject({ ...err });
    }
  });
};

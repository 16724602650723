import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import { useAuth } from "../../context/Auth";
import { useAuth0 } from "@auth0/auth0-react";
import { producers_online_post } from "api/producers";

const dbPostProducersOnline = (auth_data, payload) => {
  return new Promise((resolve) => {
    if (payload.path.startsWith("/admin")) {
      resolve();
      return;
    }

    try {
      producers_online_post(auth_data, payload)
        .then((res) => resolve({ status: true, data: res.data }))
        .catch((err) => resolve({ status: false, data: err }));

      // Error handling
    } catch (error) {
      resolve({ status: false, data: error });
    }
  });
};

export const AppViews = () => {
  const { auth } = useAuth();
  const { logout } = useAuth0();
  const { scope } = auth?.data;

  setTimeout(() => {
    dbPostProducersOnline(auth.data, { path: window.location.pathname });
  }, 250);

  const scopedRoute = (menu_scope) => {
    return scope.some((r) => menu_scope.includes(r));
  };

  if (auth?.data?.active === false) {
    // logout({ localOnly: true }); //{ returnTo: `${window.location.origin}/account-closed`}

    window.location.href = "/account-closed";
    return <></>;
  }
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route path={`${APP_PREFIX_PATH}/dashboard`} component={lazy(() => import(`./dashboard`))} />
        <Route path={`${APP_PREFIX_PATH}/onboarding`} component={lazy(() => import(`./onboarding`))} />
        <Route path={`${APP_PREFIX_PATH}/account`} component={lazy(() => import(`../shared/account`))} />
        <Route path={`${APP_PREFIX_PATH}/work-tools`} component={lazy(() => import(`./work-tools`))} />
        <Route path={`${APP_PREFIX_PATH}/management`} component={lazy(() => import(`./management`))} />

        {scopedRoute(["salespark"]) === true && (
          <Route path={`${APP_PREFIX_PATH}/sales-dashboard`} component={lazy(() => import(`./sales-dashboard`))} />
        )}

        {/* {scopedRoute(["salespark"]) === true && (
          <Route path={`${APP_PREFIX_PATH}/products/list`} component={lazy(() => import(`./products/list`))} />
        )}

        {scopedRoute(["salespark"]) === true && (
          <Route path={`${APP_PREFIX_PATH}/products/new`} component={lazy(() => import(`./products/new`))} />
        )}

        {scopedRoute(["salespark"]) === true && (
          <Route path={`${APP_PREFIX_PATH}/products/edit/:id`} component={lazy(() => import(`./products/edit`))} />
        )} */}

        {scopedRoute(["salespark"]) === true && (
          <Route path={`${APP_PREFIX_PATH}/products`} component={lazy(() => import(`./products`))} />
        )}

        {scopedRoute(["salespark"]) === true && (
          <Route path={`${APP_PREFIX_PATH}/orders`} component={lazy(() => import(`./orders`))} />
        )}

        {scopedRoute(["salespark"]) === true && (
          <Route path={`${APP_PREFIX_PATH}/buyers`} component={lazy(() => import(`./buyers`))} />
        )}

        {scopedRoute(["salespark"]) === true && (
          <Route path={`${APP_PREFIX_PATH}/courses`} component={lazy(() => import(`./courses`))} />
        )}

        {scopedRoute(["salespark"]) === true && (
          <Route path={`${APP_PREFIX_PATH}/students`} component={lazy(() => import(`./students`))} />
        )}

        {scopedRoute(["salespark"]) === true && (
          <Route path={`${APP_PREFIX_PATH}/reports`} component={lazy(() => import(`./reports`))} />
        )}

        {/* {(scopedRoute(["godmode"]) === true || scopedRoute(["staff"]) === true) && (
          <Route path={`${APP_PREFIX_PATH}/admin`} component={lazy(() => import(`./admin`))} />
        )}

        {(scopedRoute(["godmode"]) === true || scopedRoute(["staff"]) === true) && (
          <Route path={`${APP_PREFIX_PATH}/admin-2fa`} component={lazy(() => import(`../admin-2fa`))} />
        )} */}

        <Redirect from="*" to="/404" />
      </Switch>
    </Suspense>
  );
};

export default React.memo(AppViews);
